import React from "react";
import {Link} from "gatsby";

type UrlTarget = '_blank' | '_self';

interface MenuItemProps {
    label: string;
    title?: string;
    href: string;
    target?: UrlTarget,
    classList: string[]
}

const navItems: MenuItemProps[] = [
    {
        label: 'Impressum',
        href: '/impressum',
        classList: []
    },
    {
        label: 'Datenschutz',
        href: '/datenschutz',
        classList: []
    },
];


const FooterMenu: React.FC = () => {
    return (
        <ul className="flex flex-wrap flex-col font-bold uppercase">
            {navItems.map((item, i) => {
                return (
                    <li className={['menu-item', ...item.classList].join(' ')} key={i}>
                        <Link to={item.href} target={item.target}
                              title={item.title ? item.title : item.label}
                        >
                            {item.label}
                        </Link>
                    </li>
                )
            })}
        </ul>
    );
};

export default FooterMenu;
