import * as React from "react"
import Layout from "../components/Layout";
import Headline from "../components/Headline";
import SEO from "../components/seo";

export default () => {

    return (
        <Layout>
            <section>
                <div className={'container px-7.5'}>
                    <article className={'mt-18'}>
                        <Headline title={'Impressum'}></Headline>
                        <p>
                            L. Schuler GmbH & Co. KG<br/>
                            Johann-Sebastian-Bach-Straße 8<br/>
                            89264 Weißenhorn<br/>
                        </p>

                        <p>
                            Telefon: <span className={'select-all'}>07309 / 96 18-0</span><br/>
                            Telefax: <span className={'select-all'}>07309 / 96 18-10</span><br/>
                        </p>

                        <p>
                            E-Mail: info@schuler-mb.de<br/>
                            Internet: www.schuler-mb.de<br/>
                        </p>

                        <p>
                            Inhaltlich Verantwortlicher gemäß i.S.d. § 18 Abs. 2 MStV:<br/>
                            Dipl. Ing. Christoph Groß (Anschrift wie oben)
                        </p>
                    </article>
                    <article className="mt-18"><Headline title={'Rechtsdaten'}></Headline>
                        <p>
                            Kommanditgesellschaft<br/>
                            Sitz Weißenhorn
                        </p>

                        <p>
                            Pers. haftende Gesellschafterin:<br/>
                            Ludwig Schuler<br/>
                            BeteiligungsGes. mbH<br/>
                            Sitz Weißenhorn
                        </p>

                        <p>
                            Vertretungsberechtigte Geschäftsführung:<br/>
                            Gudrun Groß
                        </p>
                        <p>
                            Registergericht: Augsburg<br/>
                            Registernummer: <span className={'select-all'}>HRB 6031</span>
                        </p>

                        <p>
                            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
                            <span className={'select-all'}>DE 130837545</span><br/>
                            USt.Nr.: <span className={'select-all'}>151/167/53801</span>
                        </p>
                    </article>
                    <article className={'mt-18'}>
                        <Headline title={'Haftungshinweis'}></Headline>
                        <p>
                            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte
                            externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber
                            verantwortlich.
                        </p>
                    </article>
                    <article className={'mt-18'}>
                        <Headline title={'Copyright'}></Headline>
                        <p>
                            Sämtliche Fotos, Logos und Texte auf diesen Seiten sind Eigentum von L. Schuler GmbH & Co.
                            KG und deren Partnern. Die Vervielfältigung und/oder die Weiterverbreitung von hier zur
                            Verfügung gestellten Inhalten in jeglicher Form bedürfen der schriftlichen Genehmigung von
                            L. Schuler GmbH & Co. KG.
                        </p>
                    </article>
                    <article className={'mt-18'}>
                        <Headline title={'Realisierung'}></Headline>
                        <p>
                            <b>Konzeption und Layout:</b><br/>
                            BORST & PARTNER<br/>
                            Kommunikation . Design . Beratung<br/>
                            <a href={'https://www.borstundpartner.de/'} target={'_blank'} title={'Website in neuem Tab öffnen'} >www.borstundpartner.de</a>
                        </p>
                        <p>
                            <b>Technische Umsetzung:</b><br/>
                            Burkhardt IT Solutions<br/>
                        </p>
                    </article>
                </div>
            </section>
        </Layout>
    )
};

export const Head = () => {
    return (<SEO title={'Impressum'}></SEO>)
}
