import React from 'react';
import Header from './Header';
import Footer from './Footer';

interface LayoutProps {
    children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({children}) => {
    return (
        <>
            <Header></Header>
            <main id="main" className={'w-full'}>
                {children}
            </main>
            <Footer></Footer>
        </>
    );
};

export default Layout;
