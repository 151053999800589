import React, {useState} from "react";
import HeaderMenu from "./HeaderMenu";

// @ts-ignore
import LogoSVG from '../images/logo-white.svg';
import {Link} from "gatsby";

const Header: React.FC = () => {

    const [state, setState] = useState({
        menuVisible: false
    });

    const toggleMenuVisibility = () => {
        setState({
            ...state,
            menuVisible: !state.menuVisible
        });
    }

    const hideMenu = () => {
        setState({
            ...state,
            menuVisible: false
        });
    }

    return (
        <header id="header" className="bg-primary fixed h-[100px] z-10 w-full top-0">
            <div className="container px-7.5">
                <div className="flex flex-wrap items-center">
                    <div className="logo py-2">

                        <Link to={'/#'}>
                            <LogoSVG className="w-[120px]"></LogoSVG>
                        </Link>

                    </div>
                    <nav
                        className={`absolute bg-secondary ml-auto right-0 border-l border-white border-b top-[100px] lg:top-0 transition-transform duration-500 ${state.menuVisible ? `translate-x-0` : `translate-x-full`} lg:translate-x-0 lg:bg-primary lg:relative lg:border-0`}>
                        <HeaderMenu onMenuLinkChange={hideMenu}></HeaderMenu>
                    </nav>
                    <label htmlFor="MenuButton"
                           className={`ml-auto block p-4 w-16 h-16 cursor-pointer lg:hidden flex flex-col relative -right-4 ${state.menuVisible ? `justify-items-stretch py-5` : `justify-around`}`}>
                        <input type="checkbox" id="MenuButton" className={'peer hidden'}
                               onChange={toggleMenuVisibility}/>
                        <span
                            className={`relative block w-full h-[3px] transition-transform duration-500 bg-secondary peer-checked:bg-secondary origin-top-left ${state.menuVisible ? `rotate-45` : `rotate-0`}`}></span>
                        <span
                            className={`relative w-8/12 h-[3px] transition-transform duration-500 bg-secondary peer-checked:bg-secondary ${state.menuVisible ? `hidden` : `block`}`}></span>
                        <span
                            className={`relative block h-[3px] transition-transform duration-500 bg-secondary peer-checked:bg-secondary origin-bottom-left ${state.menuVisible ? `-rotate-45 w-full top-5` : `rotate-0 w-10/12`}`}></span>
                    </label>
                </div>
            </div>
        </header>
    );
};

export default Header;
