import React from "react";
import {Link} from "gatsby";

type UrlTarget = '_blank' | '_self';

interface MenuItemProps {
    label: string;
    title?: string;
    href: string;
    target?: UrlTarget,
    classList: string[]
}

const navItems: MenuItemProps[] = [
    {
        label: 'Reparatur',
        href: '/#reparatur',
        classList: []
    },
    {
        label: 'Prüfung',
        href: '/#prüfung',
        classList: []
    },
    {
        label: 'Wartung',
        href: '/#wartung',
        classList: []
    },
    {
        label: 'Beratung',
        href: '/#beratung',
        classList: []
    },
];


interface HeaderMenuProps {
    onMenuLinkChange: Function;
}

const HeaderMenu: React.FC<HeaderMenuProps> = (props) => {

    const onMenuLinkClick = () => {
        props.onMenuLinkChange();
    };

    return (
        <ul className="flex flex-wrap flex-col text-white uppercase font-bold lg:flex-row lg:border-0">
            {navItems.map((item, i) => {
                return (
                    <li className={['menu-item', 'md:mr-0 xl:mr[75px]', 'border-t border-t-white lg:border-0', ...item.classList].join(' ')}
                        key={i}>
                        <Link to={item.href}
                              title={item.title ? item.title : item.label}
                              className="px-14 py-4 p-1 w-full block lg:px-8"
                              onClick={onMenuLinkClick}
                        >
                            {item.label}
                        </Link>
                    </li>
                )
            })}
        </ul>
    );
};

export default HeaderMenu;
