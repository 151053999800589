import React from "react";
import {useLocation} from '@reach/router';
import {useSiteMetadata} from "../../hooks/use-site-metadata";

interface SeoProps {
    children?: any;
    title?: string;
}

const SEO: React.FC<SeoProps> = ({children, title}) => {

    const site = useSiteMetadata();
    const location = useLocation();

    const canonicalUrl = location.origin + location.pathname;

    title = title ? `${site.title} ${site.titleSeparator} ${title}` : site.title;

    return (
        <>
            <title>{title}</title>
            <meta id={'meta_description'} name={'description'} content={site.description}/>
            <meta id={'meta_author'} name={'author'} content={site.title}/>
            <meta id={'meta_publisher'} name={'publisher'} content={site.title}/>
            <meta id={'meta_copyright'} name={'copyright'} content={site.title}/>
            <meta id={'meta_robots'} name={'robots'} content={'index,follow'}/>
            <meta id={'meta_keywords'} name={'keywords'} content={site.keywords}/>
            <meta id={'meta_canonical'} name={'canonical'} content={canonicalUrl}/>
            <meta id={'og_title'} property={'og:title'} content={title}/>
            <meta id={'og_description'} property={'og:description'} content={site.description}/>
            <meta id={'og_type'} property={'og:type'} content={'Website'}/>
            <meta id={'og_site_name'} property={'og:site_name'} content={site.description}/>
            <meta id={'og_url'} property={'og:url'} content={canonicalUrl}/>
            <meta id={'ig_image'} property={'og:image'} content={site.image}/>
            {children}
        </>
    );
};

export default SEO;
