import {graphql, useStaticQuery} from "gatsby"

export const useSiteMetadata = () => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          titleSeparator
          description
          image
          siteUrl
          keywords
        }
      }
    }
  `);

    return data.site.siteMetadata;
};
