import React from "react";

interface HeadlineProps {
    title: string;
}

const Headline: React.FC<HeadlineProps> = ({title}) => {
    return (
        <div className={'headline grid items-center w-full mb-12'} id={title.toLowerCase()}>
            <div className="line w-full h-px bg-secondary" style={{gridArea: "1/1",}}></div>
            <h2 className={'text-3xl text-secondary uppercase font-bold inline bg-white justify-self-start pr-7 transition-[padding] duration-500 hover:pl-7'}
                style={{gridArea: "1/1",}}>{title}</h2>
        </div>
    );
};

export default Headline;
