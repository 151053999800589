import React from "react";
import FooterMenu from "./FooterMenu";
// @ts-ignore
import LogoSVG from '../images/logo-white.svg';
import {Link} from "gatsby";

const Footer: React.FC = () => {
    return (
        <footer className={'bg-primary text-white text-base mt-14 pb-8'}>
            <div className="container px-7.5 md:py-0">
                <div className="flex flex-col md:flex-row flex-wrap -mx-7.5 justify-between">
                    <div className={'hidden 2xl:block px-8 mt-10'}>
                        <LogoSVG className={'w-[120px]'}></LogoSVG>
                    </div>
                    <div className={'px-8 mt-10'}>
                        <h4 className={'font-bold uppercase'}>Geschäftszeiten</h4>
                        <p className={'mt-5 mb-0'}>
                            Montag bis Freitag<br/>
                            8:00 - 17:00 Uhr
                        </p>
                    </div>
                    <div className={'px-8 mt-10'}>
                        <h4 className={'font-bold uppercase'}>Telefon</h4>
                        <p className={'mt-5 mb-0'}>
                            <a href={'tel:07309961814'} title={'jetzt anrufen'}>07309 / 9618-14</a>
                        </p>
                    </div>
                    <div className={'px-8 mt-10'}>
                        <h4 className={'font-bold uppercase'}>Web</h4>
                        <p className={'mt-5 mb-0'}>
                            <a href={'mailto:info@schuler-mb.de'} title={'E-mail schrieben'}
                               target={'_blank'}>info@schuler-mb.de</a><br/>
                            <Link to={'https://www.schuler-mb.de'}>www.schuler-mb.de</Link>
                        </p>
                    </div>
                    <div className={'px-8 mt-10'}>
                        <h4 className={'font-bold uppercase'}>Adresse</h4>
                        <p className={'mt-5 mb-0'}>
                            Ludwig Schuler GmbH + Co.KG<br/>
                            Johann-Sebastian-Bach-Straße 8<br/>
                            89264 Weißenhorn
                        </p>
                    </div>
                    <div className={'px-8 mt-10'}>
                        <FooterMenu></FooterMenu>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
